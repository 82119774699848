import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";

import FileUpload from "vue-upload-component";
import UiDocumentDrop from "./UiDocumentDrop/UiDocumentDrop.vue";
import UiDocumentAlert from "./UiDocumentAlert/UiDocumentAlert.vue";
import UiDocumentProgress from "./UiDocumentProgress/UiDocumentProgress.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import UIComponentMixin from "../UIComponentMixin";
import HttpMixin from "../../../mixins/HttpMixin";
import SyConfigMixin from "../../../mixins/SyConfigMixin";
import MimeType from "./MimeType";
import {
  ignoreErrorHandlerFor413,
} from "../../../functions/utils";
import {
  forEach,
  isPlainObject,
  isArray,
  isUndefined,
  get,
} from "lodash-es";
const SIZE_MB = 25;

// @vue/component
export default {
  name: "UiDocument",
  components: {
    FileUpload,
    PuxTranslate,
    UiDocumentDrop,
    UiDocumentAlert,
    UiDocumentProgress,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    UIComponentMixin,
  ],
  setup() {
    const {
      addNotification,
    } = ANotificationAPI();

    return {
      addNotification,
    };
  },
  data() {
    return {
      files: [],
      allowedMimeTypeSyConfig: [],
      sizeMB: SIZE_MB,
      size: 1024 * 1024 * SIZE_MB,
      statusLoading: true,
      // url: "tempdok/",
      progressList: [],
      errorsLocal: [],
    };
  },
  computed: {
    getInputId() {
      const ID = this.options.htmlId || `${ this.idPrefix }${ this.options.attrId || this.options.key || this.options.id }`;
      return `${ ID }_upload`;
    },

    isModelArray() {
      return isArray(this.model);
    },

    isModelObject() {
      return isPlainObject(this.model);
    },

    getModelName() {
      return get(this.model, this.getKeyLabel) || get(this.model, "titel") || get(this.model, "datei");
    },

    getKeyLabel() {
      return this.options.keyLabel || "name";
    },

    isDisabled() {
      return this.options.disabled || this.disabled;
    },

    getErrorPlaceholders() {
      return {
        maxSize: "_MSG_DOCUMENTS_MAX_SIZE_ERROR_{{sizeMb}}_",
        type: "_MSG_DOCUMENTS_DATA_TYPE_ERROR_{{documentTypes}}_",
        server: "Das Speichern der Datei im Dokumentenmanagement-System ist fehlgeschlagen.",
        extra: {
          sizeMb: SIZE_MB,
          documentTypes: this.optionsLocal.documentTypes,
        },
      };
    },

    getProgressHideTime() {
      return isUndefined(this.options.progressHideTime) ?
        5000 :
        this.options.progressHideTime;
    },

    optionsLocal() {
      const OPTIONS = {
        accept: "",
        mimeTypeRegExp: "",
        documentTypes: undefined,
        allowedMimeType: undefined,
        allowedMimeTypeLabel: "",
      };
      const ALLOWED_MIME_TYPE_OPTIONS = this.options.allowedMimeType && this.options.allowedMimeType.length ?
        this.options.allowedMimeType :
        undefined;
      const mimetypes = ALLOWED_MIME_TYPE_OPTIONS || this.allowedMimeTypeSyConfig;
      OPTIONS.allowedMimeType = mimetypes.join(",").replace(/\./g, "");
      OPTIONS.documentTypes = mimetypes.join(", ");
      let mimeTypeRegExp = "\\.(";
      forEach(mimetypes, (item, index) => {
        const TYPE = item.replace(/\./g, "");
        OPTIONS.accept = `${ OPTIONS.accept }${ index !== 0 ? "," : "" }${ MimeType[TYPE] }`;
        mimeTypeRegExp += `${ index !== 0 ? "|" : "" }${ TYPE }`;
        OPTIONS.allowedMimeTypeLabel = `${ OPTIONS.allowedMimeTypeLabel }${ index !== 0 ? ", " : "" }*${ item }`;
      });
      OPTIONS.allowedMimeTypeLabel = `(${ OPTIONS.allowedMimeTypeLabel })`;
      mimeTypeRegExp += ")$";
      OPTIONS.mimeTypeRegExp = new RegExp(mimeTypeRegExp, "i");
      return OPTIONS;
    },

    buttonTextTranslate() {
      return this.options.buttonText || "_BTN_UI_DOCUMENT_";
    },
  },
  created() {
    this.initData();
  },
  methods: {
    saveDocument(file) {
      if (this.options.saveStart) {
        this.options.saveStart(file);
      }
      if (this.options.saveUrl) {
        const PROGRESS_INDEX = this.progressList.length;
        this.progressList.push({
          progress: 50,
          label: file.name,
          show: true,
          id: PROGRESS_INDEX,
        });
        const formData = new FormData();
        formData.append("file", file.file);
        this.postHttp({
          url: this.options.saveUrl,
          urlParams: this.options.saveUrlParams,
          data: formData,
          ignoreErrorHandler: ignoreErrorHandlerFor413,
        }).then(
          response => {
            // Workaround für PA-4108
            // Da die Ursache nicht ganz klar ist (Es gibt keine Schritte zum Reproduzieren),
            // besagt der Debug, dass der Server nicht schuld sein darf.
            // Ohne diesen Workaround hat der Client manchmal die vollständige Antwort
            // an den Server gesendet oder vom Server erhalten und danach gesendet,
            // was zu einem Fehler führt.
            const RESPONSE = get(response, "data") || response;
            this.onInput(RESPONSE);
          },
          errors => {
            if (errors.status === 413) {
              this.addNotification({
                text: "_MSG_DOCUMENT_ERROR_413_",
                type: "error",
              });
              const errorData = ["_MSG_DOCUMENT_ERROR_413_"];
              this.progressList[PROGRESS_INDEX].errors = errorData;
              this.options.saveError(errorData);
              return;
            }
            if (this.options.saveError) {
              let errorData = get(errors, "data", errors);
              if (!isArray(errorData)) {
                errorData = [errorData];
              }
              this.progressList[PROGRESS_INDEX].errors = errorData;
              this.options.saveError(errorData);
            }
          }
        ).finally(
          () => {
            if (this.options.saveDone) {
              this.options.saveDone();
            }
            this.progressList[PROGRESS_INDEX].progress = 100;
            if (this.getProgressHideTime) {
              setTimeout(() => {
                this.progressList[PROGRESS_INDEX].show = false;
              }, this.getProgressHideTime);
            }
          }
        );
        return new Promise(resolve => {
          resolve(file);
        });
      }
      const PROMISE = new Promise(resolve => {
        resolve(this.onInput(file));
      });
      return PROMISE;
    },

    initData() {
      this.allowedMimeTypeSyConfig = this.getSyConfigsValue("allowed_mime_type") || [];

      this.statusLoading = false;
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (newFile.size > this.size) {
          this.addError({ newFile, errorType: "maxSize" });
          return prevent();
        } else if (!this.checkMimeType({ newFile })) {
          this.addError({ newFile, errorType: "type" });
          return prevent();
        }
      }
    },

    checkMimeType({ newFile }) {
      if (!this.optionsLocal.allowedMimeType.length) {
        return true;
      }
      return this.optionsLocal.mimeTypeRegExp.test(newFile.name);
    },

    inputFileDrop({ newFile, oldFile, $refsUpload }) {
      if (newFile && !oldFile) {
        // add
        $refsUpload.active = true;
        // this.onInput(newFile);
      }
      if (newFile && oldFile) {
        // update
        // console.log("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        // console.log("remove", oldFile);
      }
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        this.$refs.upload.active = true;
        // this.onInput(newFile);
      }
      if (newFile && oldFile) {
        // update
        // console.log("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        // console.log("remove", oldFile);
      }
    },

    onInput(response) {
      this.onInput_mixin({ value: response });
    },

    addError({ newFile, errorType }) {
      const ERROR = {
        label: newFile.name,
        error: errorType,
      };
      this.errorsLocal.push(ERROR);
    },

    deleteErrors() {
      this.errorsLocal = [];
    },
  },
};

import NotificationMixin from "./NotificationMixin";

import {
  clearApiSaved as AClearApiSaved,
  deleteHttp as ADeleteHttp,
  getHttp as AGetHttp,
  getListHttp as AGetListHttp,
  getOptionsHttp as AGetOptionsHttp,
  patchHttp as APatchHttp,
  postHttp as APostHttp,
  putHttp as APutHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  forEach,
} from "lodash-es";

export default {
  mixins: [
    NotificationMixin,
  ],
  methods: {
    clearApiSaved({ apiSaveId }) {
      return AClearApiSaved({ apiSaveId });
    },

    getHttp({
      url,
      urlBase,
      data,
      urlParams = {},
      headerParams,
      responseType,
      apiSaveId,
      keyId,
      fullResponse,
      showError,
      ignoreErrorHandler,
      abortable,
      abortGroup,
    }) {
      return AGetHttp({
        url,
        urlBase,
        data,
        urlParams,
        headerParams,
        responseType,
        apiSaveId,
        keyId,
        fullResponse,
        showError,
        ignoreErrorHandler,
        abortable,
        abortGroup,
      });
    },

    getListHttp({
      url,
      urlBase,
      data,
      urlParams = {},
      headerParams,
      responseType,
      apiSaveId,
      keyId,
      fullResponse,
      showError,
      ignoreErrorHandler,
      abortable,
      abortGroup,
    }) {
      return AGetListHttp({
        url,
        urlBase,
        data,
        urlParams,
        headerParams,
        responseType,
        apiSaveId,
        keyId,
        fullResponse,
        showError,
        ignoreErrorHandler,
        abortable,
        abortGroup,
      });
    },

    getOptionsHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      keyId,
      fullResponse,
      showError,
    }) {
      return AGetOptionsHttp({
        url,
        data,
        urlParams,
        headerParams,
        responseType,
        keyId,
        fullResponse,
        showError,
      });
    },

    postHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
      ignoreErrorHandler,
    }) {
      return APostHttp({
        url,
        data,
        urlParams,
        headerParams,
        responseType,
        fullResponse,
        showError,
        ignoreErrorHandler,
      });
    },

    putHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return APutHttp({
        url,
        data,
        urlParams,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    patchHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return APatchHttp({
        url,
        data,
        urlParams,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    deleteHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return ADeleteHttp({
        url,
        data,
        urlParams,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    getChoicesHttp({
      id,
      label,
      url,
      params = {},
    } = {}) {
      const list = [];
      if (id && label) {
        params.fields = [id, label];
      }
      const PROMISE = new Promise((resolve, reject) => {
        this.getHttp({ url, urlParams: params }).then(
          response => {
            const RESULTS = response.results || response;
            if (id && label) {
              forEach(RESULTS, item => {
                list.push({
                  value: item[id],
                  label: item[label],
                });
              });
              return resolve(list);
            }
            return resolve(RESULTS);
          },
          error => {
            return reject(error);
          }
        );
      });
      return PROMISE;
    },
  },
};
